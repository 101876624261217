<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">

    <v-overlay :value="isLoading">
      <div class="car-animation">

        <img src="@/assets/wind.png" alt="smoke" class="smoke"/>

        <div class="car">
          <img src="@/assets/chassis.png" alt="Chassis" class="car-body"/>
          <img src="@/assets/wheel.png" alt="front wheel" class="wheel front-wheel"/>
          <img src="@/assets/wheel.png" alt="back wheel" class="wheel back-wheel"/>
        </div>
      </div>
    </v-overlay>

    <v-row  v-if="!isLoading" no-gutters>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! search inputs                                                                                                ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row justify="center" no-gutters>
          <v-form ref="form">
            <!-- ------- ->
            <! line 1    ->
            <!- ---------->
            <v-row no-gutters class="mt-4">
              <!-- ------- ->
              <! from day  ->
              <!- ---------->
              <v-col cols="auto">
                <w-date :val.sync="fromDay" label="from day"/>
              </v-col>

              <!-- ------- ->
              <! from hour ->
              <!- ---------->
              <v-col cols="auto" class="mx-8">
                <w-hour-minute
                  :val.sync="fromHour"
                  label="from hour"
                  def="06:00"
                />
              </v-col>

              <!-- -------------- ->
              <! client reference ->
              <!- ----------------->
              <v-col cols="auto">
                <v-text-field
                  label="client order ref."
                  placeholder="e.g.: 104220180000646"
                  v-model="clientReference"
                  clearable
                  outlined
                />
              </v-col>
            </v-row>

            <!-- ------- ->
            <! line 2    ->
            <!- ---------->
            <v-row no-gutters>
              <!-- ------- ->
              <! until day ->
              <!- ---------->
              <v-col cols="auto">
                <w-date :val.sync="untilDay" label="until day"/>
              </v-col>

              <!-- -------- ->
              <! until hour ->
              <!- ----------->
              <v-col cols="auto" class="mx-8">
                <w-hour-minute
                  :val.sync="untilHour"
                  label="until hour"
                  def="22:00"
                />
              </v-col>

              <!-- --------- ->
              <! receiver ID ->
              <!- ------------>
              <v-col cols="auto">
                <v-text-field
                  label="receiver"
                  placeholder="e.g.: 73, NOR1012, etc"
                  v-model="receiverID"
                  clearable
                  outlined
                />
              </v-col>
            </v-row>

            <!-- ------------ ->
            <!- search button ->
            <!- --------------->
            <v-row no-gutters>
              <v-col class="d-flex flex-row-reverse">
                <v-btn color="primary" @click="search">search</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-row>

        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! search results                                                                                               ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row justify="center" class="ma-4">
          <v-col xs="12" sm="12" md="12">
            <v-card class="elevation-6">
              <w-entity-list
                :columns="columns"
                :items="orders"
                :read-path="readPath"
                read-ref="orderReference"
                title="Orders"
                sort-by="created,desc"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  VBtn,
  VCard,
  VCol,
  VContainer,
  VForm,
  VRow,
  VTextField,
} from "vuetify/lib";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import WDate from "../../components/external/modules/shared/views/WDate.vue";
import WHourMinute from "../../components/external/modules/shared/views/WHourMinute.vue";
import api from "../../components/external/modules/shared/utils/api";
import path from "../../shared/paths";
import dateformat from "../../components/external/modules/shared/utils/dateformat";
import datehourformat from "../../components/external/modules/shared/utils/datehourformat";
import lsync from "../../components/external/modules/shared/utils/lsync";

// used to transform a date in the local timezone to a UTC date with format 'YYYY/MM/DD hh:mm'
const toUTC = (dateString) => {
  const [dayString, hourString] = new Date(dateString)
    .toISOString()
    .slice(0, 19)
    .split("T");
  return dateformat(dayString) + " " + hourString;
};

const field = Object.freeze({
  FROMDAY: "Orders.fromDay",
  FROMHOUR: "Orders.fromHour",
  UNTILDAY: "Orders.untilDay",
  UNTILHOUR: "Orders.untilHour",
  CLIENTREF: "Orders.clientRef",
  RECEIVER: "Orders.receiver",
});

export default {
  components: {
    VBtn,
    VCard,
    VCol,
    VContainer,
    VForm,
    VRow,
    VTextField,
    WDate,
    WEntityList,
    WHourMinute,
  },

  data: () => ({
    isLoading: false,
    fromDay: lsync(field.FROMDAY) || new Date().toJSON().slice(0, 10),
    fromHour: lsync(field.FROMHOUR) || "06:00",
    untilDay: lsync(field.UNTILDAY) || new Date().toJSON().slice(0, 10),
    untilHour: lsync(field.UNTILHOUR) || "22:00",
    clientReference: lsync(field.CLIENTREF) || "",
    receiverID: lsync(field.RECEIVER) || "",
    orders: [],
    columns: [
      { value: "_createdBy", text: "client" },
      { value: "orderReference", text: "hamp ref." },
      { value: "created", text: "created" },
      { value: "orderClientReference", text: "client ref." },
      { value: "nbItems", text: "nb. items" },
      { value: "orderStatusString", text: "status" },
      { value: "receiverID", text: "receiver" },
    ],
    readPath: path.ORDER,
  }),

  methods: {
    search() {
      this.isLoading = true;

      const portfolio = {};
      portfolio.fromTimeString = toUTC(
        dateformat(lsync(field.FROMDAY, this.fromDay)) +
          " " +
          lsync(field.FROMHOUR, this.fromHour)
      );
      portfolio.untilTimeString = toUTC(
        dateformat(lsync(field.UNTILDAY, this.untilDay)) +
          " " +
          lsync(field.UNTILHOUR, this.untilHour)
      );
      portfolio.orderClientReference = lsync(
        field.CLIENTREF,
        this.clientReference
      );
      portfolio.receiverID = lsync(field.RECEIVER, this.receiverID);

      this.orders = []; // cleaning the orders

      // performing the search
      api
        .post("portfolio/read", portfolio)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            this.orders = res.data.entity.orders.map((order) => {
              // formatting the creation date - NB: this should probably be put into a reusable function
              order.created = datehourformat(order._creation);
              return order;
            });
          } else {
            console.log(new Error("Failed searching the orders"));
            console.log(res);
          }
          setTimeout(() => {
            this.isLoading = false
          }, 200)
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.isLoading = false
          }, 200)
        });
    },
  },
};
</script>

<style scoped>
.car-animation {
  position: relative;
  width: 300px; /* Ajustez selon la taille de votre image de voiture */
  height: 150px;
  margin: 0 auto;
}

/* Image de la fumée */
.smoke {
  position: absolute;
  bottom: 73px;
  left: -45px;
  width: 60px;
  transform: scaleX(-1);
}

/* Conteneur de la voiture */
.car {
  bottom: 90px;
  left: 25px;
  position: relative;
  width: 100%;
  height: auto;
}

/* Image de la carrosserie de la voiture */
.car-body {
  width: 80%;
  height: auto;
}

/* Style commun pour les roues */
.wheel {
  position: absolute;
  width: 50px; /* Taille de la roue */
  height: 50px;
  animation: spin 1s linear infinite; /* Animation de rotation */
}

/* Position de la roue avant */
.front-wheel {
  bottom: 50px; /* Ajustez pour coller au passage de roue avant */
  left: 23px;  /* Ajustez pour la position horizontale */
}

/* Position de la roue arrière */
.back-wheel {
  bottom: 50px; /* Ajustez pour coller au passage de roue arrière */
  left: 167px; /* Ajustez pour la position horizontale */
}

/* Animation de rotation des roues */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

