import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":"mdi-calendar","readonly":"","append-outer-icon":"mdi-refresh"},on:{"click:append-outer":function($event){return _vm.refreshDateValue()}},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){return _vm.$refs.dateMenu.save(_vm.innerVal)}},model:{value:(_vm.innerVal),callback:function ($$v) {_vm.innerVal=$$v},expression:"innerVal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }