import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',{staticStyle:{"padding-left":"12px"}},[_vm._v(_vm._s(_vm.getTitle))]),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.filter,"sort-by":_vm.localSortBy,"sort-desc":_vm.localSortDescBool,"footer-props":{'items-per-page-options':[5, 10, 25, 50, -1]},"items-per-page":_vm.itemsPerPageInt,"page":_vm.currentPage},on:{"update:sortBy":function($event){_vm.localSortBy=$event},"update:sort-by":function($event){_vm.localSortBy=$event},"update:sortDesc":function($event){_vm.localSortDescBool=$event},"update:sort-desc":function($event){_vm.localSortDescBool=$event},"update:itemsPerPage":function($event){_vm.itemsPerPageInt=$event},"update:items-per-page":function($event){_vm.itemsPerPageInt=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEntity(item, $event)}}},[_vm._v("mdi-eye")])]}},{key:"no-data",fn:function(){return [_c(VAlert,{attrs:{"value":true}},[_vm._v("Nothing to display here")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }