<template>
  <v-card>
    <v-card-title>
      <span style="padding-left:12px">{{ getTitle }}</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="filter"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="filter"
      :sort-by.sync="localSortBy"
      :sort-desc.sync="localSortDescBool"
      :footer-props="{'items-per-page-options':[5, 10, 25, 50, -1]}"
      :items-per-page.sync="itemsPerPageInt"
      :page.sync="currentPage"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="openEntity(item, $event)">mdi-eye</v-icon>
      </template>

      <template v-slot:no-data>
        <v-alert :value="true">Nothing to display here</v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import lsync from "../../shared/utils/lsync";
import {
  VAlert,
  VCard,
  VCardTitle,
  VDataTable,
  VIcon,
  VSpacer,
  VTextField,
} from "vuetify/lib";

export default {
  name: "w-entity-list",

  components: {
    VAlert,
    VCard,
    VCardTitle,
    VDataTable,
    VIcon,
    VSpacer,
    VTextField,
  },

  props: {
    columns: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    readPath: { type: String, default: "" },
    readRef: { type: String, default: "" },
    rows: { type: String, default: "10" },
    sortBy: { type: String, default: "" },
    title: { type: String, default: "[TITLE]" },
  },

  data: () => ({
    currentPage: 1,
    filter: "",
    itemsPerPageString: "50",
    itemsPerPageInt: 50,
    localSortBy: "",
    localSortDescString: "asc",
    localSortDescBool: false,
  }),

  computed: {
    getTitle() {
      return this.title + " (" + this.items.length + ")";
    },
    headers() {
      if (this.showAction) {
        return [
          ...this.columns,
          { value: "action", text: "action", sortable: false },
        ];
      }
      return this.columns;
    },
    showAction() {
      return this.readRef !== "";
    },
  },

  watch: {
    currentPage(newValue) {
      this.currentPage = lsync(this.currentPageUid, newValue);
    },

    filter(newValue) {
      if (newValue === null) {
        newValue = "";
      }
      this.filter = lsync(this.filterUid, newValue);
    },

    items() {
      this.currentPage = parseInt(lsync(this.currentPageUid)) || 1;
    },

    itemsPerPageInt(newValue) {
      this.itemsPerPageInt = newValue;
      this.itemsPerPageString = lsync(
        this.itemsPerPageUid,
        this.itemsPerPageInt === -1 ? "All" : this.itemsPerPageInt.toString()
      );
    },

    localSortBy(newValue) {
      this.localSortBy = lsync(this.sortByUid, newValue);
    },

    localSortDescBool(newValue) {
      this.localSortDescBool = newValue;
      this.localSortDescString = lsync(
        this.sortDescUid,
        this.localSortDescBool === true ? "desc" : "asc"
      );
    },
  },

  created() {
    // parsing the prop on the sorting
    if (this.sortBy.indexOf(",") > 0) {
      let elems = this.sortBy.split(",");
      this.sortByCol = elems[0];
      this.sortByDesc = elems[1] === "desc";
    } else {
      this.sortByCol = this.sortBy;
      this.sortByDesc = "asc";
    }

    // building unique IDs (= keys for the local storage)
    this.tableUid = this.$route.path + "-" + this.title;
    this.currentPageUid = this.tableUid + "-currentPage";
    this.filterUid = this.tableUid + "-filter";
    this.itemsPerPageUid = this.tableUid + "-itemsPerPage";
    this.sortByUid = this.tableUid + "-sortBy";
    this.sortDescUid = this.tableUid + "-sortDesc";
    this.tableUid = this.$route.path + "-" + this.title;

    // initialising the fields
    this.filter = lsync(this.filterUid);
    this.itemsPerPageString = lsync(this.itemsPerPageUid) || this.rows; // if there's nothing in the local storage, let's take the value coming from the prop
    this.itemsPerPageInt =
      this.itemsPerPageString === "All"
        ? -1
        : parseInt(this.itemsPerPageString); // the prop in v-data-table needs an integer, though
    this.localSortBy = lsync(this.sortByUid) || this.sortByCol; // if there's nothing in the local storage, let's take the value coming from the prop
    this.localSortDescString = lsync(this.sortDescUid) || this.sortByDesc; // if there's nothing in the local storage, let's take the value coming from the prop
    this.localSortDescBool = this.localSortDescString === "desc"; // the prop in v-data-table needs a boolean, though
  },

  methods: {
    openEntity(selected, event) {
      const entity = this.items.find((ent) => ent.id === selected.id);
      const route = {
        name: this.readPath,
        params: { which: entity[this.readRef] },
      };
      const routeData = this.$router.resolve(route);
      const url = routeData.href;

      if (event.ctrlKey || event.metaKey) {
        // Si Ctrl ou Cmd est enfoncé, ouvrir dans un nouvel onglet
        window.open(url, '_blank');
      } else {
        // Sinon, navigation normale avec le routeur Vue
        this.$router.push(route);
      }
    },
  },
};
</script>

<style scoped>
</style>
