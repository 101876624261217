import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"timeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":"mdi-clock-time-four-outline","readonly":"","append-outer-icon":"mdi-refresh"},on:{"click:append-outer":function($event){return _vm.refreshTimeValue()}},model:{value:(_vm.innerVal),callback:function ($$v) {_vm.innerVal=$$v},expression:"innerVal"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[(_vm.menuOpen)?_c(VTimePicker,{attrs:{"full-width":"","format":"24hr"},on:{"change":function($event){return _vm.$refs.timeMenu.save(_vm.innerVal)}},model:{value:(_vm.innerVal),callback:function ($$v) {_vm.innerVal=$$v},expression:"innerVal"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }